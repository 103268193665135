<mat-card *ngFor="let d of metricsHourData" class="metrics-card mat-elevation-z1">
    <mat-card-title class="metric-title">
            Hour {{d.Hour}}: {{d.Start}} - {{d.End}}
    </mat-card-title>
    <mat-card-content (click)="navigateToProblems(d.Hour)">
        <div class="grid-container-hour">
            <!-- ROW: Hour Total-->
            <div class="perfect">{{d.Plan}}</div>
            <div class="target">{{d.Target}}</div>
            <div class="actual">{{d.Actual}}</div>
            <div class="efficiency"><span *ngIf="d.Plan > 0">{{floor((d.Actual / d.Plan) * 100)}}%</span></div>
            <div class="variance">{{d.Actual - d.Plan}}</div>
            <div class="problemstatus">
                <mat-icon *ngIf="d.Plan > 0 && (d.Actual > 0 || d.Target == 0) && d.ProblemStatus == 'Complete'" style="color: #32CD00; font-weight: 1000 !important; float: right !important;">check</mat-icon>
                <mat-icon *ngIf="d.Plan > 0 && d.Actual == 0 && d.Target > 0 && d.ProblemStatus == 'Complete'" style="color: orange; font-weight: 1000 !important; float: right !important;">event_busy</mat-icon>
                <mat-icon *ngIf="d.Plan > 0 && d.ProblemStatus == 'Incomplete'" style="color: red; float: right !important;">clear</mat-icon>
                <mat-icon *ngIf="d.Plan > 0 && d.ProblemStatus == 'InProgress'" style="color: orange; float: right !important;">warning</mat-icon>
            </div>

            <!-- ROW: Shift Totals up to the hour -->
            <div class="actualtotal" *ngIf="d.Plan > 0">{{d.ActualTotal}}</div>
            <div class="variancetotal" *ngIf="d.Plan > 0">{{d.ActualTotal - d.PlanTotal}}</div>

            <!-- Variable content (Problems, HourNotes, or PartCount) will span both rows and overflow if needed-->
            <div *ngIf="true" class="hourcontent">
                <!-- Problems View  -->
                <div *ngIf="section == 'problems' && problemsExist[d.Hour - 1]"  [matTooltip]="tooltipValues.hourProblem" matTooltipPosition="above">
                    <div *ngFor="let problem of metricsTopProblemsData[d.Hour - 1]['Problems']; let idx = index">
                        <div class="grid-container-hourcontent" >
                            <div class="item1">{{problem.Problem}}</div>
                            <div class="item2">{{problem.Duration}}</div>
                        </div>
                    </div>
                </div>
                <!-- End: Problems view -->
                
                <!-- Hourly Note View -->
                <div *ngIf="section == 'hourNotes'">
                    <div  *ngFor="let note of d.Notes; let idx = index">
                        <div class="grid-container-hourcontent" >
                            <div class="item1">-{{note.Note}}</div>
                            <div class="item2">-{{note.CorrectiveAction}}</div>
                        </div>
                    </div>
                </div>
                <!-- End: Hourly Note View -->

                <!-- Part count View -->
                <div *ngIf="section == 'partCount'">
                    <div  *ngFor="let part of partCount[d.Hour]; let idx = index">
                        <div class="grid-container-partcontent" >
                            <div class="part-item1">{{part.JobID}}</div>
                            <div class="part-item2">{{part.JobDescription}}</div>
                            <div class="part-item3">{{part.PartNumber}}</div>
                            <div class="part-item4">{{part.Quantity}}</div>
                        </div>
                    </div>
                </div>
                <!-- End: Part Count View -->
            </div>
            <!-- End: Variable Content-->

        </div>
    </mat-card-content>
</mat-card>