<form [formGroup]="objFormGroup">    
<h3 mat-dialog-title>{{title}}</h3>
<div mat-dialog-content>
  

                    

            <mat-form-field>
                <mat-select placeholder="Site" formControlName="PlantName" (selectionChange)="selectPlant()" cdkFocusInitial>
                  <mat-option *ngFor="let d of plantList" [value]="d.display_name">{{d.display_name}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-divider></mat-divider>

            <mat-form-field>
                <textarea matInput cdkTextareaAutosize placeholder="Object ID" formControlName="ObjectID"></textarea>
            </mat-form-field>

            <mat-divider></mat-divider>

            <mat-form-field>
                <textarea matInput cdkTextareaAutosize placeholder="Section" formControlName="Section"></textarea>
            </mat-form-field>

            <mat-divider></mat-divider>

            <mat-form-field>
                <textarea matInput cdkTextareaAutosize placeholder="Sub Section" formControlName="SubSection"></textarea>
            </mat-form-field>

            <mat-divider></mat-divider>

            <mat-form-field>
                <textarea matInput cdkTextareaAutosize placeholder="Description" formControlName="Description"></textarea>
            </mat-form-field>
 
            <mat-divider></mat-divider>

            <mat-form-field>
                <mat-select placeholder="Type" formControlName="ObjectType">
                    <mat-option value="GROUP">Group</mat-option>
                    <mat-option value="ASSEMBLY">Assembly</mat-option>
                    <mat-option value="FAB">Fab</mat-option>
                  </mat-select>
            </mat-form-field>  
 
            <mat-divider></mat-divider>

            <mat-form-field>
                <mat-select placeholder="Count Collection" formControlName="ManCount">
                  <mat-option [value]="0">Automatic</mat-option>
                  <mat-option [value]="1">Manual</mat-option>
                </mat-select>
            </mat-form-field>            

            <mat-divider></mat-divider>

            <mat-form-field>
                <mat-select placeholder="Problem Collection" formControlName="ManProb">
                  <mat-option [value]="0">Automatic</mat-option>
                  <mat-option [value]="1">Manual</mat-option>
                  <mat-option [value]="2">Auto and Manual</mat-option>
                </mat-select>
            </mat-form-field>            
            
            <mat-divider></mat-divider>
            <div class="fx-row-start-stretch" > 
                <div fxFlex="50">
                    <mat-form-field>
                        <mat-select placeholder="Require Note on Problem Create" formControlName="ProbNoteReq">
                        <mat-option [value]="0">Optional</mat-option>
                        <mat-option [value]="1">Required</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div fxFlex="50">
                    <mat-form-field>
                        <mat-select placeholder="Min Required Problem Level" formControlName="ProbLevelReq">
                        <mat-option [value]="0">0</mat-option>
                        <mat-option [value]="1">1</mat-option>
                        <mat-option [value]="2">2</mat-option>
                        <mat-option [value]="3">3</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <mat-divider></mat-divider>

            <mat-form-field>
                <mat-select placeholder="Problem Group" formControlName="ProblemGroup" cdkFocusInitial>
                  <mat-option *ngFor="let d of problemGroups" [value]="d.ProblemGroup">{{d.Description}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-divider></mat-divider>

            <mat-form-field>
                <mat-select placeholder="Team Group (optional)" formControlName="TeamGroup" cdkFocusInitial>
                  <mat-option *ngFor="let d of teamGroups" [value]="d.TeamGroup">{{d.Description}}</mat-option>
                </mat-select>
            </mat-form-field>         
            <mat-accordion>            
                <mat-expansion-panel [expanded]="false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Test and Repair Settings (Optional)
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                        <mat-form-field>
                            <textarea matInput cdkTextareaAutosize placeholder="​Test Line" formControlName="TestLine"></textarea>
                        </mat-form-field>
            
                        <mat-divider></mat-divider>
                        
                        <mat-form-field>
                            <textarea matInput cdkTextareaAutosize placeholder="​Test Station" formControlName="TestStation"></textarea>
                        </mat-form-field>
            
                        <mat-divider></mat-divider>
                        
                        <mat-form-field>
                            <textarea matInput cdkTextareaAutosize placeholder="​Test Area" formControlName="TestArea"></textarea>
                        </mat-form-field>
            
                        <mat-divider></mat-divider>
                        
                        <mat-form-field>
                            <textarea matInput cdkTextareaAutosize placeholder="​Test Type" formControlName="TestType"></textarea>
                        </mat-form-field>
            
                        <mat-divider></mat-divider>
            
                        <mat-form-field>
                            <textarea matInput cdkTextareaAutosize placeholder="​Repair Line" formControlName="RepairLine"></textarea>
                        </mat-form-field>
            
                        <mat-divider></mat-divider>
                        
                        <mat-form-field>
                            <textarea matInput cdkTextareaAutosize placeholder="​Repair Location" formControlName="RepairLocation"></textarea>
                        </mat-form-field>
            
                        <mat-divider></mat-divider>
            
                        <mat-form-field>
                            <mat-select placeholder="Test ​Schema Name" formControlName="SchemaName" cdkFocusInitial>
                            <mat-option *ngFor="let d of trSchemaList" [value]="d.SCHEMA_NAME">{{d.SCHEMA_NAME}}</mat-option>
                            </mat-select>
                        </mat-form-field>          
                </mat-expansion-panel>
            </mat-accordion>
            
</div>   
<div mat-dialog-actions>
    <button mat-raised-button (click)="saveData()" [disabled]="objFormGroup.invalid || isSaving">Save</button>
    <button mat-raised-button (click)="onNoClick()">Cancel</button>
</div>
</form>

<div *ngIf="isSaving">
    <mat-spinner></mat-spinner>
</div>