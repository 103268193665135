<mat-card class="metrics-card mat-elevation-z1">
    <mat-card-title>
        <div class="grid-container-header"> 
            <div class="perfect" matTooltipClass = "mat-multiline-tooltip" [matTooltip]="tooltipValues.perfect" matTooltipPosition="above">Perfect</div>
            <div class="target" matTooltipClass = "mat-multiline-tooltip" [matTooltip]="tooltipValues.target" matTooltipPosition="above">Customer Demand</div>
            <div class="actual" [matTooltip]="tooltipValues.actual" matTooltipPosition="above">Actual</div>
            <div class="efficiency" [matTooltip]="tooltipValues.efficiency" matTooltipPosition="above">AY</div>
            <div class="variance" [matTooltip]="object.ObjectType == 'ASSEMBLY' ? tooltipValues.varianceAY : tooltipValues.varianceOA" matTooltipPosition="above">Variance</div>
            <div class="variableContent">
                <div class="grid-container-content" *ngIf="section=='problems'">
                    <div class="item1">Problem</div>
                    <div class="item2">Duration</div>
                </div>
                <div class="grid-container-content" *ngIf="section=='hourNotes'">
                    <div class="item1">Note</div>
                    <div class="item2">Counter Measure</div>
                </div>
                <div class="grid-container-partcontent" *ngIf="section=='partCount'">
                    <div class="part-item1">Job ID</div>
                    <div class="part-item2">Job Description</div>
                    <div class="part-item3">Part Number</div>
                    <div class="part-item4">Quantity</div>
                </div>
            </div>
            <div class="equipstatus"></div>
        </div>        
    </mat-card-title>
    <mat-card-content>
        <div class="grid-container-header"> 
            <div class="perfect"><strong>{{metricsHeaderData.Plan}}</strong></div>
            <div class="target"><strong>{{floor(metricsHeaderData.TargetDemand)}}</strong></div>
            <div class="actual"><strong>{{metricsHeaderData.Actual}}</strong></div>
            <div class="efficiency"><strong>{{floor(metricsHeaderData.Yield)}}%</strong></div>
            <div class="variance"><strong>{{metricsHeaderData.Actual - floor(metricsHeaderData.Plan)}}</strong></div>
            <div class="variableContent">
                <div class="grid-container-content">
                    <div class="item1" [matTooltip]="tooltipValues.problem" matTooltipPosition="above">
                        <span *ngIf="section =='problems' && metricsHeaderData.Status != '0'">
                            <strong>{{metricsHeaderData.EventStart | date:'HH:mm:ss'}}</strong>
                        </span>
                    </div>
                    <div class="item2" [matTooltip]="tooltipValues.duration" matTooltipPosition="above">
                        <span *ngIf="section =='problems' && metricsHeaderData.Status != '0'">
                            <strong>{{metricsHeaderData.EventDuration}}</strong>
                        </span>
                    </div>
                </div>
            </div>
            <div class="equipstatus"><img  (click)="navigateToCurrentProblem($event)" style="height: 25px; float: right !important;" [src]="'assets/' + metricsHeaderData.Status + '.png'"/></div>
        </div>
    </mat-card-content>
</mat-card>